import React from 'react';
import './App.css';
import Navbar from "../containers/Navbar";
import Footer from "../containers/Foooter";
import {ContactContent, HomeContent, ResumeContent, WorkContent} from "../containers/Contents";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: "home",
            currentContent: <HomeContent onClicked={this.onButtonClicked}/>
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.currentPage !== this.state.currentPage) {
            console.log("Alakazam")
            if (this.state.currentPage === "home") {
                this.setState({currentContent: <HomeContent onClicked={this.onButtonClicked}/>})
            } else if (this.state.currentPage === "works") {
                this.setState({currentContent: <WorkContent onClicked={this.onButtonClicked}/>})
            }
            else if (this.state.currentPage === "contact") {
                this.setState({currentContent: <ContactContent onClicked={this.onButtonClicked}/>})
            }
            else if (this.state.currentPage === "resume") {
                this.setState({currentContent: <ResumeContent onClicked={this.onButtonClicked}/>})
            }
        }
    }

    onButtonClicked = (event) => {
        this.setState({currentPage: event.target.id})
    }


    render() {
        return (
            <div>
                <Navbar buttonOnClick={this.onButtonClicked} currentPage={this.state.currentPage}/>
                {this.state.currentContent}
                <Footer currentPage={this.state.currentPage}/>
            </div>
        );
    };
}

export default App;
