import React from "react";
import './Contents.css'

const LargeButton = (props) => {
    return (
        <button onClick={props.onClicked} id={props.buttonId} className={'button-large button-large-' + props.buttonType}>
            {props.buttonName}
        </button>
    )
}

const HomeContent = (props) => {
    return (
        <div className="content content-home justify-content-center">
            <div className="tc pa4">
                <img src={require("./portfolio_suyog.jpg")} className="br-100 pa1 ba b--black-10 h3 w3" alt="avatar"/>
            </div>
            <div className="pa4">
                <h1 className="tc">Suyog Adhikari</h1>
                <hr/>
                <p style={{lineHeight: '2'}}>
                    I am AI developer currently providing computer vision solutions to <a style={{color: '#fb0'}}
                                                                                          href="https://8squarei.com/">EightSquare.</a><br/>
                    I don't write much, but am thinking of maintaining a blog mainly focused on machine learning.<br/>
                    I am open to work, and can help you with:
                    <ul>
                        <li style={{color: '#fb0'}}>Image processing using OpenCV</li>
                        <li style={{color: '#fb0'}}>Building Android SDK/library using JNI/C++</li>
                        <li style={{color: '#fb0'}}>Deep learning mainly focused on Computer Vision</li>
                        <li style={{color: '#fb0'}}>Building web application using Django Rest Framework</li>
                        <li style={{color: '#fb0'}}>Build you a stunning portfolio website like this one (^_^)</li>
                    </ul>
                    Outside of computer science, I actually waste a lot of time watching anime and try play as little
                    DOTA as possble.
                </p>
                <hr/>
                <div className="homeButtons">
                    <LargeButton buttonId = "works" onClicked = {props.onClicked} buttonName="See my works" buttonType="works"/>
                    <LargeButton buttonId = "contact" onClicked = {props.onClicked} buttonName="Contact me" buttonType="contact"/>
                </div>
            </div>
        </div>
    )
}

const WorkContent = (props) => {
    return(
        <div style={{margin: '0', position: 'absolute', top: '50%', left: '0', right: '0'}} className="center content-work tc">
            <h1 className='content-works'>Coming soon</h1>
        </div>
    )
}

const ContactContent = (props) => {
    return(
        <div style={{margin: '0', position: 'absolute', top: '50%', left: '0', right: '0'}} className="center content-contact tc">
            <h1 className='content-contact'>Coming soon</h1>
        </div>
    )
}

const ResumeContent = (props) => {
    return(
        <div style={{margin: '0', position: 'absolute', top: '50%', left: '0', right: '0'}} className="center tc">
            <h1 className='content-resume'>Coming soon</h1>
        </div>
    )
}
export {HomeContent, WorkContent, ContactContent, ResumeContent};