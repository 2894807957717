import React from "react";
import './Navbar.css'

const NavButton = (props) => {
    return (
        <a id={props.buttonId} href={props.buttonUrl} className={'aButton ' + props.buttonClass}
           onClick={props.buttonOnClick}> {props.buttonName} </a>
    );
}

const Navbar = (props) => {
    return (
        <div className={"fixed-top navbar navbar-" + props.currentPage}>
            <NavButton buttonId="home" buttonUrl="#" buttonClass={"button button-home"} buttonName="Home"
                       buttonOnClick={props.buttonOnClick}/>
            <NavButton buttonId="works" buttonUrl="#" buttonClass={"button button-works"} buttonName="Works"
                       buttonOnClick={props.buttonOnClick}/>
            <NavButton buttonId="contact" buttonUrl="#" buttonClass={"button button-contact"} buttonName="Contact"
                       buttonOnClick={props.buttonOnClick}/>
            <NavButton buttonId="resume" buttonUrl="#" buttonClass={"button button-resume"} buttonName="Resume"
                       buttonOnClick={props.buttonOnClick}/>
        </div>
    );
}

export default Navbar;